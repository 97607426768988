import './components/send';
import './components/modal';
import './components/menu';
import './components/courusel';
import './components/read-more';
import './components/dropdown';
import './components/slider';





import Swiper, { Navigation, Scrollbar } from 'swiper';
Swiper.use([Navigation, Scrollbar]);
const swiper = new Swiper('.trainers__content', {

  slidesPerView: 2,
  loop: true,
  spaceBetween: 30,
   navigation: {
    nextEl: '.swiper-button-prev',
    prevEl: '.swiper-button-next',
  },



    breakpoints: {

      800: {
        slidesPerView: 3,
        spaceBetween: 10
      },
      1100: {
        slidesPerView: 4,
        spaceBetween: 10
      },

    }

});


$('.menu__sublist').parent().children('a.menu__item').addClass('menu__item--active');

$('.menu__sublist').parent().children('a.menu__subitem').addClass('menu__item--active');


$('.menu__item, .menu__subitem').click(function(){
  $(this).next('.menu__sublist').slideToggle();
  $(this).toggleClass('menu__item--open');
});

// $('.promotion__sublist').parent().children('a.promotion__item').addClass('promotion__item--active');

// $('.promotion__sublist').parent().children('a.promotion__subitem').addClass('promotion__item--active');


// $('.promotion__item, .promotion__subitem').click(function(){
//   $(this).next('.promotion__sublist').slideToggle();
//   $(this).toggleClass('promotion__item--open');
// });
